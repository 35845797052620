import React, { useState } from 'react';
import './CityDropdown.css'; // Assuming you have a CSS file for styles

const CityDropdown = ({ onSelect }) => {

  const handleCityChange = (event) => {
    onSelect(event.target.value);
  };

  return (
    <div>
      <h3>If you want local products, select the site's city</h3>
      <select defaultValue="" onChange={handleCityChange}>
        <option value="">Select city</option>
        <option value="Mumbai">Mumbai</option>
        <option value="Delhi">Delhi</option>
        <option value="Hyderabad">Hyderabad</option>
        <option value="Bangalore">Bangalore</option>
        <option value="Chennai">Chennai</option>
        <option value="Kolkata">Kolkata</option>
        <option value="Ahmedabad">Ahmedabad</option>
        <option value="Pune">Pune</option>
      </select>
    </div>
  );
};

export default CityDropdown;
