import React, { useState } from 'react';
import CityDropdown from './CityDropdown';
import './ImageUpload.css'; // Assuming you have a CSS file for styles

function ImageUpload({ onSubmit }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [inputBudget, setBudget] = useState(1000000);
  const [inputCity, setCity] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);
    } else {
      setSelectedFile(null);
      setFileName('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      alert('Please select a file first.');
      return;
    }
  
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile); // Converts the file to base64 string
    reader.onload = async () => {
      const base64String = reader.result.split(',')[1]; // Removes the data url part "data:image/png;base64,"
      console.log('STARTED 1');
      try {
        const response = await fetch('https://21ipqkizaj.execute-api.us-west-1.amazonaws.com/dev/uploadImage', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ image: base64String, 'image_name' : fileName}),
        });
        console.log('STARTED 2');
        if (response.ok) {
          const resultData = await response.json();
          console.log(resultData);
          const imageUrl = resultData.imageUrl;
          console.log('STARTED 3');
          onSubmit(imageUrl, inputBudget, inputCity);
        } else {
          alert('Upload failed');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('Upload failed');
      }
    };
    reader.onerror = (error) => {
      console.error('Error converting image to base64:', error);
    };
  };
  

  return (
    <div className="upload-container">
      <CityDropdown onSelect={setCity}/>
      <h2>Upload an image for which you want matching products</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="file"
          accept=".png, .jpg, .jpeg"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="file-input"
        />
        <label htmlFor="file-input" className="fancy-button">
          Upload a file
        </label>
        <label htmlFor="budget">Enter budget (in rupees):</label>
        <input
            type="range"
            id="budget"
            name="budget"
            min="50000"
            max="1000000"
            value={inputBudget}
            onChange={(e) => setBudget(e.target.value)}
            className="slider"
        />
        <div>{`Budget: ₹${inputBudget}`}</div>
        {fileName && <span className="file-name">{fileName}</span>}
        
        <button type="submit" className="fancy-button">Find matching products</button>
      </form>
    </div>
  );
}

export default ImageUpload;
