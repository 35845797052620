// FloatingButton.jsx

import React from 'react';
import ReactGA from 'react-ga4';
import './FloatingButton.css'; // Make sure to create this CSS file

const FloatingButton = () => {

  const handleClick = () => {

    ReactGA.event({
      category: 'Form',
      action: 'Consult Button',
    });
  };

  return (
    <a href="https://forms.gle/97JYsqhZLkWGZJkZ9" target="_blank" rel="noopener noreferrer" onClick={handleClick} className="floating-button">
      Consult an interior designer for FREE
    </a>
  );
};

export default FloatingButton;
