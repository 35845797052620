import React, { useState } from 'react';
import MultipleImageUploadButton from './MultipleImageUpload';
import './InputForm.css';

const InputForm = ({ onSubmit }) => {
  const [inputText, setInputText] = useState('');
  const [inputBudget, setBudget] = useState(1000000);
  const [files, setFiles] = useState([]);
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    onSubmit(inputText, inputBudget); // Pass the state up to the parent component
  };

  const handleFilesChange = (fileList) => {
    setFiles(fileList);
    // Now you have access to the file list in the parent component
};

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="textInput" className='prompt-text'>Enter a description of your design requirements:</label>
      <label htmlFor="textInput" className='prompt-text'>Upload some previous images you liked</label>
      <div>
            <MultipleImageUploadButton onFilesChange={handleFilesChange} />
            <div>
                <ul>
                    {files.map((file, index) => (
                        <li key={index}>{file.name}</li>
                    ))}
                </ul>
            </div>
        </div>
      <div className="prompt-text-smaller">Example prompt: A contemporary style bedroom in a blue and white base with a bed, nightstand, and a fancy chandelier</div>
      <textarea
        type="text"
        id="textInput"
        name="textInput"
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        required
        className="large-text-input" 
      />
      <label htmlFor="budget">Enter budget (in rupees):</label>
      <input
          type="range"
          id="budget"
          name="budget"
          min="50000"
          max="1000000"
          value={inputBudget}
          onChange={(e) => setBudget(e.target.value)}
          className="slider"
      />
      <div>{`Budget: ₹${inputBudget}`}</div>
      <div className="button-container">
        <button type="submit">Submit</button>
      </div>
    </form> 
  );
};

export default InputForm;
