/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_pWlfymJCy",
    "aws_user_pools_web_client_id": "45prhlmuqjmel6058k6nvfdjsn",
    "oauth": {
        "domain": "starpenter.auth.us-west-1.amazoncognito.com",
        "scope": ["email", "openid", "profile"],
        "redirectSignIn": "https://www.starpenter.com/", // Adjust as needed
        "redirectSignOut": "https://www.starpenter.com", // Adjust as needed
        // "redirectSignIn": "http://localhost:3000/", // Adjust as needed
        // "redirectSignOut": "http://localhost:3000/", // Adjust as needed
        "responseType": "code" // or 'token', depending on your flow
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
