// ImageCollage.js

import React from 'react';
import './ImageCollage.css';

import ReactGA from 'react-ga4';

function ImageCollage({
  imageUrls,
  selectedImage,
  setSelectedImage, // Ensure setSelectedImage is being passed and used
  handleSearchSubmit,
  showCollage, // Ensure showCollage is being managed appropriately
}) {
  // Handler for image selection
  const handleImageSelect = (index) => {
    // Google Analytics event tracking
    ReactGA.event({
        category: 'Image Selection', // This is a generic category name, customize it as needed
        action: 'Select Image', // This describes the action taken by the user
        label: `Image ${index + 1}`, // This could be a dynamic label indicating which image was selected
        // value: 'someValue', // Optional: if there's a numerical value associated with the event, include it here
      });
    setSelectedImage(index);
  };

  return (
    <div className="image-collage">
      {showCollage ? (
        <>
          <h2 className="beautiful-title">Select a design:</h2>
          <div className="image-collage-container">
            {imageUrls.map((imageUrl, index) => (
              <div key={index} className={`image-wrapper ${selectedImage === index ? 'selected' : ''}`} onClick={() => handleImageSelect(index)}>
                <img src={imageUrl} alt={`Design ${index + 1}`} className="collage-image" />
              </div>
            ))}
          </div>
          <p className="small-beautiful-text">Click on an image to select it</p>
          <div className="button-wrapper">
            <button className="fancy-button" onClick={handleSearchSubmit}>Fetch product list</button>
          </div>
        </>
      ) : (
        selectedImage !== null && (
          <div className="image-wrapper selected">
            <img src={imageUrls[selectedImage]} alt={`Selected Design ${selectedImage + 1}`} className="collage-image" />
            {/* Consider including a back button or similar to return to the collage */}
          </div>
        )
      )}
    </div>
  );
}

export default ImageCollage;
