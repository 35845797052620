import React, { useState } from 'react';
import ScrollingImages from './ScrollingImages'; 
import './Introduction.css';

const Introduction = () => {
    return (<div className="Introduction">
    <h1 className="header gradient-text"><i className="fas fa-paint-roller"></i> Room Designer</h1>
    <div className="description">
  <p>Welcome to the Room Designer! Our tool makes it easy to visualize your dream space:</p>
  <ul>
      <li>Describe your ideal room with budget</li>
      <li>Select a beautiful design</li>
      <li>Submit design to get matching products</li>
  </ul>
  {/* <p>Let’s create something beautiful together!</p> */}
    </div>
    <span className="bubbly-text">Let's create something beautiful together!</span>
    <ScrollingImages />
    <div className="green-divider"></div>
    </div>);
}

export default Introduction;
