import React, { useState, useEffect, useRef } from 'react';
import './ScrollingImages.css'; // Assume you have a CSS file for styles

const images = [
  'https://decorroomimages.s3.amazonaws.com/preey_1399_a_beautiful_latin_living_room_--v_6_4792fcb3-b97c-4602-84ec-4b28b608127c_3+(1).png',
  'https://decorroomimages.s3.amazonaws.com/preey_1399_a_beautiful_balcony_with_outdoor_seating_--v_6_3cb77592-75b2-410b-9a38-5a73ffbe14d6_3.png',
  'https://decorroomimages.s3.amazonaws.com/preey_create_a_colourful_bedroom_with_a_desk_two_chairs_a_sin_6b01a3f2-bbe6-4a8e-97f7-09cbbd4c34a6_1+(1).png',
  'https://decorroomimages.s3.amazonaws.com/preey_1399_a_luxury_living_room_with_a_royal_theme_--v_6_ab5724df-723b-4366-80c5-8c4a580fd10a_1.png',
  'https://decorroomimages.s3.amazonaws.com/preey_1399_an_elegant_living_room_--v_6_c304a083-94f7-4262-8153-33920bb5e7f0_1.png',
  

  // ...more images
];

const ScrollingImages = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  return (
    <div className="scrolling-image-container">
      {/* Only render the active image */}
      <img src={images[currentIndex]} style={{ width: '40%' }} alt="Displayed Content" />
      <div className="dot-container">
        {images.map((_, index) => (
          <Dot key={index} isActive={index === currentIndex} />
        ))}
      </div>
    </div>
  );
};

const Dot = ({ isActive }) => (
  <span className={`dot ${isActive ? 'active' : ''}`}></span>
);

export default ScrollingImages;
