import React from 'react';
import ItemCard from './ItemCard';
import './ItemList.css'; 

function ItemList({ items }) {
    return (
      <div className="item-list">
        {items.map(item => (
          <ItemCard key={item.position.toString()} item={item} />
        ))}
      </div>
    );
  }

export default ItemList;
