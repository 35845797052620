import React, { useState, useEffect } from 'react';
import ItemList from './ItemList';
import Introduction from './Introduction';
import InputForm from './InputForm';
import ImageCollage from './ImageCollage';
import GoogleForm from './GoogleForm';
import FloatingButton from './FloatingButton';
import Products from './Products';
import ProductItem from './ProductItem';
import ImageUpload from './ImageUpload';
import './App.css';
import {Amplify} from 'aws-amplify';
import awsExports from './aws-exports'; // if you're using the Amplify CLI, it generates this file'

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'; // Import the styles


import ReactGA from 'react-ga4';

Amplify.configure(awsExports);
const TRACKING_ID = "G-LWLWVF0QX4"; // OUR_TRACKING_ID

// function transformList(lists) {
//   return lists.map(([product, description]) => ({
//     product,
//     description,
//   }));
// }

function App() {
  const [imageUrls, setImageUrl] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showLabour, setShowLabour] = useState(false);
  const [marketplace, setMarketplace] = useState([]);
  const [laborUsage, setlaborUsage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [showCollage, setShowCollage] = useState(true); // New state to control visibility
  const [toAuthenticate, setToAuthenticate] = useState(false);
  const [inputBudget, setBudget] = useState(1000000);
  const [description, setDescription] = useState('');
  const [toDisplay, setToDisplay] = useState(true);
  const [totalBudget, setTotalBudget] = useState(0);

  useEffect(() => {
    let budget = 0;
    marketplace.forEach(items => {
      // console.log(items);
      if (items.matches.length > 0) {
        var price = parseFloat(items.matches[0].price.replace(/[^0-9.-]+/g, ""));
        budget += parseFloat(price);
      }
      if (items.misc[1]) {
        budget += parseFloat(items.misc[1].replace(/[^0-9.-]+/g, ""));
      }
      if (items.misc[4]) {
        budget += parseFloat(items.misc[4].replace(/[^0-9.-]+/g, ""));
      }
    });
    setTotalBudget(budget);
  }, [marketplace]);

  const handleBudget = async (inputText, inputBudget) => {
    setBudget(inputBudget);
    try {
      console.log('Sending request to budget Python script');
      const data = { "budget": inputBudget, "prompt" : inputText };
      console.log(data);
      const response = await fetch('https://21ipqkizaj.execute-api.us-west-1.amazonaws.com/dev/prepareBudget', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      // Send a remote post request with query parameters
      if (response.ok) {
        console.log('Python script executed budgets');
        const resultData = await response.json();
        console.log(resultData);
        const prompt = resultData.prompt;
        return prompt;
      } else {
        console.error('Budget script execution failed');
        return inputText;
      }
    } catch (error) {
      console.error('Budget error occurred:', error);
      return inputText;
    }
  };

  const handleFormSubmit = async (inputText, inputBudget) => {
    setShowCollage(true);
    setIsLoading(true); // Set loading to true before API call
    setMarketplace([]);
    setImageUrl([]);

    const promptText = await handleBudget(inputText, inputBudget);

    ReactGA.event({
      category: 'Form',
      action: 'Submit Attempt',
      label: promptText,
    });
    
    try {
      console.log('Sending request to Python script');
      const data = { "query": promptText };
      const response = await fetch('https://21ipqkizaj.execute-api.us-west-1.amazonaws.com/dev/generateImage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      // Send a remote post request with query parameter

      setIsLoading(false); // Set loading to false after API call
      console.log('Here');
      if (response.ok) {
        console.log('Python script executed successfully run_python_script');

        ReactGA.event({
          category: 'API',
          action: 'Successful Submission',
          label: 'Generate Image'
        });

        const resultData = await response.json();
        console.log(resultData);
        const resultImageUrls = resultData.imageUrls;
        setImageUrl(resultImageUrls);
        setShowImage(true);
      } else {
        console.error('Python script execution failed');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const fetchImagesParallel = async (descriptions, city, laborInfo) => {
    console.log('Fetch images');
    console.log(descriptions);
    try {
      // Map each description to a fetch promise
      const fetchPromises = descriptions.map((desc, index) => {
        console.log('Sending request to Python script searchImage', desc);
        return fetch('https://21ipqkizaj.execute-api.us-west-1.amazonaws.com/dev/searchImage', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ "description": [desc] , "budget": inputBudget , "city": city, "labour" : laborInfo}),
        })
        .then(response => {
          console.log("Request sent ", index);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          // console.log('Index ', response.json())
          return response.json(); // Parse JSON only if response was ok
        })
        .then(resultData => {
          console.log('Python script executed successfully searched image');
          return resultData["shopping_results"]; // Assuming resultData has shopping_results
        })
        .catch(error => {
          console.error('Python script execution failed or request failed', error);
          // Handle individual request error without breaking the entire promise chain
          return []; // Return an empty array or suitable error indicator
        });
      });
  
      // Use Promise.all to wait for all promises to resolve
      const results = await Promise.all(fetchPromises);
  
      // Flattening the results array and updating the state or handling data
      const new_marketplace = [].concat(...results);
      console.log('New Marketplace Results');
    console.log(new_marketplace);

    // Use the functional update form to ensure the latest state value
    setMarketplace(prevMarketplace => {
      const final_marketplace = new_marketplace.concat(prevMarketplace);
      console.log('Final Marketplace');
      console.log(final_marketplace);
      return final_marketplace;
    });
      
      // Set loading to false after API calls are done
      // this.setState({ loading: false });
  
      // Handle the aggregated results here
      // For example, update state or context with new_marketplace data
  
    } catch (error) {
      console.error('An error occurred:', error);
      // Handle any errors that occurred during the initialization of the promises
      // Set loading to false in case of error
      // this.setState({ loading: false });
    }
  }

  const fetchProductList = async (imageUrl, city) => {
    console.log('Selected image');
    console.log(imageUrl);
    try {
      console.log('Sending request to Python script segment image');
      const lambdaFunctionUrl = 'https://b3veinclm23mfb3xhejrkpqceq0fziic.lambda-url.us-west-1.on.aws/';

    const response = await fetch(lambdaFunctionUrl, {
      method: 'POST', // Assuming your Lambda function is expecting a POST request
      headers: {
        'Content-Type': 'application/json',
        // If your Lambda function URL requires authentication, you might need to add an Authorization header here
      },
      body: JSON.stringify({"imageUrl": imageUrl}),
    });
      console.log('Selected image 2');

      ReactGA.event({
        category: 'API',
        action: 'Successful Search',
        label: 'Product Image'
      });
      
      if (response.ok) {
        console.log('Python script executed successfully segment image');
        const description_json = await response.json();
        const description = description_json["description"];
        // setDescription(transformList(description));
        console.log('Description');
        console.log(description);
        await fetchImagesParallel(description, city, false);      
        setIsSearchLoading(false);
        setShowProducts(true);
      } else {
        console.error('Python script execution failed');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  const fetchLabourList = async (imageUrl, city) => {
    console.log('Selected image Labour');
    console.log(imageUrl);
    try {
      console.log('Sending request to Python calculate labor');
      const lambdaFunctionUrl = 'https://uxpj4k35jgwz74khwerpn3oug40yhmcr.lambda-url.us-west-1.on.aws/';

    const response = await fetch(lambdaFunctionUrl, {
      method: 'POST', // Assuming your Lambda function is expecting a POST request
      headers: {
        'Content-Type': 'application/json',
        // If your Lambda function URL requires authentication, you might need to add an Authorization header here
      },
      body: JSON.stringify({"imageUrl": imageUrl}),
    });
      console.log('Selected image labour 2');

      ReactGA.event({
        category: 'API',
        action: 'Successful Search',
        label: 'Product Image'
      });
      
      if (response.ok) {
        console.log('Python script executed successfully labour');
        const description_json = await response.json();   
        setIsSearchLoading(false);
        setShowLabour(true);
        console.log(description_json);
        const description = description_json["description"];
        // setDescription(transformList(description));
        console.log('Description labour');
        console.log(description);
        await fetchImagesParallel(description, city, true);  
        setlaborUsage(description_json.description);
        console.log(laborUsage)
      } else {
        console.error('Python script execution failed');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  

  const handleImageSubmit = async (imageUrl, budget, city) => {
    const imageUrls_one = [imageUrl];
    console.log('STARTED');
    setImageUrl(imageUrls_one);
    setSelectedImage(0);
    setShowImage(true);
    setShowCollage(false);
    setIsSearchLoading(true);
    setToDisplay(false);
    setBudget(budget);

    ReactGA.event({
      category: 'Image Search',
      action: 'Image Search Attempt',
      label: 'Product Image Search'
    });

    await fetchProductList(imageUrl, city);
    // await fetchLabourList(imageUrl, city);
  }

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    setIsSearchLoading(true); // Set loading to true before API call
    setToAuthenticate(true);
    setShowCollage(false);

    ReactGA.event({
      category: 'Search',
      action: 'Search Attempt',
      label: 'Product Image Search'
    });

    await fetchProductList(imageUrls[selectedImage], '');
  };

  ReactGA.initialize(TRACKING_ID);

  return (
    <div className="App">
      <Introduction />
      <ImageUpload onSubmit={handleImageSubmit} />
      <div className="green-divider"></div>
      {toDisplay && <InputForm onSubmit={handleFormSubmit} />}         
            {/* {isLoading && <GoogleForm 
            link="https://docs.google.com/forms/d/e/1FAIpQLSeAjh0bo_Bj_oaXTStP2JgueVJMTsyxQCobw5yY1wE92QuKBQ/viewform?embedded=true"/>} */}
            {isLoading && (<div className="loading">Loading...</div>)}
          {showImage && (
            <div>
              {showImage && (
          <ImageCollage
            imageUrls={imageUrls}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            handleSearchSubmit={handleSearchSubmit}
            showCollage={showCollage}
          />
        )}
            </div>
          )}
          <FloatingButton />
    {/* {showProducts && <Products productList={marketplace} />} */}
    {showProducts && (
        <>
          <div className="total-budget">Total Budget: ₹{totalBudget.toFixed(2)}</div>
          {marketplace.map((items, i) => (
            <div key={i}>
              <ProductItem items={items} />
            </div>
          ))}
        </>
      )}
    {isSearchLoading && <div className="loading">Search results awaited...</div>}
      <div className="green-divider"></div>
          {showProducts && <GoogleForm 
          link="https://docs.google.com/forms/d/e/1FAIpQLSeKJpL5YLQKf_MLar7g06c1Ikc56rlC7U7m79CTAkeRQRXxYw/viewform?embedded=true"/>} 
    </div>
  );
}

export default App;


  // const handleImageClick = (e) => {
  //   const rect = e.target.getBoundingClientRect();
  //   const scaleX = e.target.naturalWidth / e.target.offsetWidth;  // scale factor for width
  //   const scaleY = e.target.naturalHeight / e.target.offsetHeight; // scale factor for height

  //   const x = Math.floor((e.clientX - rect.left) * scaleX); // calculate X coordinate, adjust for scaling
  //   const y = Math.floor((e.clientY - rect.top) * scaleY);  // calculate Y coordinate, adjust for scaling
  //   setpixelX(x);
  //   setpixelY(y);
  //   console.log(`Clicked pixel: X=${x}, Y=${y}`);
  //   // You can now use x and y for your purposes
  // };
