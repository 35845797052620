import React from 'react';

const GoogleForm = ({link}) => {
  return (
    <div style={{margin: 'auto', width: 'fit-content'}}>
      <iframe src={link} width="640" height="400" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  );
};

export default GoogleForm;
