import React from 'react';
import './MultipleImageUpload.css';

function MultipleImageUploadButton({ onFilesChange }) {
    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        onFilesChange(files);  // Call the parent callback function with the new files
    };

    return (
        <div className="image-upload-container">
            <input
                id="file-upload"
                type="file"
                className="image-upload-input"
                onChange={handleFileChange}
                multiple
                accept="image/*"
            />
            <label htmlFor="file-upload" className="custom-file-upload">
                Inspiration images
            </label>
        </div>
    );
}

export default MultipleImageUploadButton;
