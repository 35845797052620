import React from 'react';
import ReactGA from 'react-ga4';
import './ItemCard.css'; // Assuming you have a separate CSS file for styles

function ItemCard({ item }) {
  const handleLinkClick = (event) => {
    // Prevent default action is not necessary here since we're opening the link in a new tab
    // Log the event with Google Analytics
    ReactGA.event({
      category: 'Link Click',
      action: ' Marketplace Click',
      label: 'Link to ' + item.link, // Custom label, could be anything
      // 'dimensionX' should be replaced with your custom dimension identifier
      marketplacelink: item.link, // Assuming 'item.link' holds the URL you want to track
    });

    // No need to manually navigate since the link already has 'href' and 'target="_blank"'
  };
  return (
    <div className="card">
      <a href={item.link} target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}>
        <img src={item.thumbnail} alt="Thumbnail" className="thumbnail" />
      </a>
      <div className="info">
        {/* <img src={item.source_icon} alt="Source Icon" className="source-icon" /> */}
        <span className="source-name">{item.source}</span>
        {item.price && (<span className="price">{item["price"]}</span>)}
        {item.rating && (
          <div className="ratings">
            <span>{'⭐'.repeat(Math.floor(item.rating))}</span>
            <span>{item.rating.toFixed(1)}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default ItemCard;
