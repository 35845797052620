import React, { useState } from 'react';
import ItemList from './ItemList'; // Adjust the path as necessary
import './ProductItem.css'; // Import the CSS file here

const ProductItem = ({ items }) => {
  const [showItems, setShowItems] = useState(false);

  const toggleItems = () => {
    setShowItems(!showItems);
  };

  return (
    <div className="product-item-container">
  <div className="product-header">
    <hr />
    <h2>{items["product"]}</h2>
    {/* Conditional rendering for Allocated Budget */}
    {items["budget"] && (
      <h2 style={{ textAlign: 'right' }}>Allocated Budget: {items["budget"]}</h2>
    )}
  </div>
  <button onClick={toggleItems} className="toggle-button">
    {showItems ? 'Hide Matches' : 'Show Matches'}
  </button>
  {items["misc"][1] && (
        <div className="labor-cost">
          Labor Cost: {items["misc"][1]}
        </div>
      )}
  {(items["misc"][4]) && (
        <div className="material-cost">
          Material Cost: {items["misc"][4]}
        </div>
      )}
  {/* Labor cost display */}
      
  {showItems && <ItemList items={items["matches"]} />}
  
  {showItems && items["misc"][0] && items["misc"][2] && (
            <div className="labor-required">
              Explaination of cost: {items["misc"][0]}, {items["misc"][2]}</div>
          )}
  
</div>
  );
};

export default ProductItem;
